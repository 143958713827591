import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Material and other packages
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TranslateUniversalLoader } from '../../translate-universal';


// Fonts
import { FontNunitoComponent } from './fonts/nunito/nunito.component';
import { FontCairoComponent } from './fonts/cairo/cairo.component';
import { FontMaliComponent } from './fonts/mali/mali.component';
import { FontNotoComponent } from './fonts/noto/noto.component';
import { FontOpenComponent } from './fonts/open/open.component';
import { FontDevanagariComponent } from './fonts/devanagari/devanagari.component';

// Pipes
import { CurrencySpacePipe } from './pipes/currency-space.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { ThemeSourcePipe } from './pipes/theme-source.pipe';

// Top Banners
import { BannerComponent } from '../layout/banner/banner.component';
import { TopBarComponent } from '../layout/top-bar/top-bar.component';

import { SharedRoutingModule } from './shared-routing.module';
import { PaymentTypesPipe } from './pipes/payment-types.pipe';

// Swiperjs
import { register } from 'swiper/element/bundle';
register();

@NgModule({
  declarations: [
    FilterPipe,
    CurrencySpacePipe,
    BannerComponent,
    TopBarComponent,
    FontNunitoComponent,
    FontCairoComponent,
    FontMaliComponent,
    FontNotoComponent,
    FontOpenComponent,
    ThemeSourcePipe,
    PaymentTypesPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateUniversalLoader
      }
    }),
  ],
  exports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatBottomSheetModule,
    MatRadioModule,
    MatCardModule,
    MatTabsModule,
    MatProgressBarModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    LazyLoadImageModule,
    TranslateModule,
    BannerComponent,
    FilterPipe,
    CurrencySpacePipe,
    ThemeSourcePipe,
    PaymentTypesPipe,
    FontNunitoComponent,
    FontCairoComponent,
    FontMaliComponent,
    FontNotoComponent,
    FontOpenComponent,
    TopBarComponent,
  ]
})
export class SharedModule { }
