<app-splash-screen></app-splash-screen>
<div id="root" [ngClass]="{'rtl': isRtl}">

  <ng-container [ngSwitch]="lang">
    <app-font-cairo        *ngSwitchCase="'ar'"></app-font-cairo>
    <app-font-mali         *ngSwitchCase="'th'"></app-font-mali>
    <app-font-noto         *ngSwitchCase="'zh-hans'"></app-font-noto>
    <app-font-open         *ngSwitchCase="'el'"></app-font-open>
    <app-font-devanagari   *ngSwitchCase="'hi'"></app-font-devanagari>
    <app-font-nunito       *ngSwitchDefault></app-font-nunito>
  </ng-container>

  <div class="router">
    <router-outlet></router-outlet>
  </div>

</div>
